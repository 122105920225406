<script lang="ts" setup>
const props = withDefaults(
	defineProps<{
		right?: boolean;
		gap?: string;
		items?: string;
		justify?: string;
		col?: boolean;
	}>(),
	{
		right: false,
		gap: "15px",
		items: "start",
		justify: "start",
		col: false
	}
);

const alignItems = computed(() => {
	if (props.items === "start" || props.items === "end") {
		return `flex-${props.items}`;
	} else if ([
		"between",
		"around",
		"evenly"
	].includes(props.items)) {
		return `space-${props.items}`;
	} else {
		return props.items;
	}
});

const justifyContent = computed(() => {
	if (props.justify === "start" || props.justify === "end") {
		return `flex-${props.justify}`;
	} else if ([
		"between",
		"around",
		"evenly"
	].includes(props.justify)) {
		return `space-${props.justify}`;
	} else {
		return props.justify;
	}
});

const customClasses = computed(() => {
	return {
		"is-col": props.col,
		"align-right": props.right
	};
});
</script>

<template>
	<div
		:class="customClasses"
		class="row"
	>
		<slot />
	</div>
</template>

<style lang="scss" scoped>
.row {
	@apply flex flex-row w-full;
	gap: v-bind(gap);
	align-items: v-bind(alignItems);
	justify-content: v-bind(justifyContent);

	&.align-right {
		@apply justify-end;
	}

	&.is-col {
		@apply flex-col;
	}
}
</style>
